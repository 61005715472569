@import '../../../../scss/theme-bootstrap';

.basic-formatter {
  &__background {
    position: relative;
    width: 100%;
    &-content {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.desktop-display-off {
    .basic-formatter__item {
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  &.mobile-display-off {
    .basic-formatter__item {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
}
